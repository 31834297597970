import { signInWithEmailAndPassword } from "firebase/auth";
// import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import { useForm } from "react-hook-form";

function Login() {

    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
  
    const onSubmit = async (data) => {
      const { email, password } = data;
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        var user = userCredential.user;
        console.log("Inicio de sesión exitoso", user);
  
        navigate("/");
      } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Error al iniciar sesión", errorCode, errorMessage);
      }
    };

  return (
    <>
      {/* <Helmet>
        <title>Login - Daniel Ospid</title>
      </Helmet> */}

      <section className="section">
        <div className="container">
          <div className="login-page">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="block">
                <span className="title">Panel de Control</span>
              </div>
              <div className="block">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
              </div>
              <div className="block">
                <input
                  type="password"
                  placeholder="Contraseña"
                  {...register("password", { required: true, minLength: 8 })}
                />
              </div>
              <div className="block">
                <button type="submit" className="button primary-button">
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
export default Login