import { Link } from "react-router-dom";
import { db } from "../../firebase/config";
import useIsAdmin from "../../firebase/fbQueries";
import note from "../../images/note.svg";

function MusicCard(props) {
  const isAdmin = useIsAdmin(db);
  return (
    <>
      {(props.show || isAdmin) && (
        <div className="music-card">
          {isAdmin === false ? (
            <Link
              to={`/${props.path}`}
              target="_blank"
              className="music-card-link"
            >
              <div className="music-card-cover">
                {props.covers ? (
                  <img
                    src={props.covers[0]}
                    alt={"Portada de " + props.title}
                    title={"Portada de " + props.title}
                    className="music-cover"
                    loading="eager"
                  />
                ) : (
                  <div
                    className="music-cover"
                    style={{ backgroundColor: `#${props.color}80` }}
                  >
                    • • •
                  </div>
                )}
                {props.songs && (
                  <div
                    className="music-card-songs"
                    style={{ backgroundColor: `rgb(0 0 0 / 65%)` }}
                  >
                    <span className="music-card-songs-icon">
                      <img
                        src={note}
                        alt="Nota musical"
                        title="Nota musical"
                        loading="eager"
                        width={16}
                        height={16}
                      />
                    </span>
                    <span className="music-card-songs-text">
                      {props.songs.length > 1 ? (
                        <>{props.songs.length + " Canciones"}</>
                      ) : (
                        <>{props.songs.length + " Canción"}</>
                      )}
                    </span>
                  </div>
                )}
                <div className="music-card-screen"></div>
              </div>
              <div className="music-card-caption">
                <span className="music-card-title">{props.title}</span>
                <span className="music-card-type">
                  {`${props.type} • ${
                    props.date &&
                    new Date(props.date.seconds * 1000).toLocaleDateString(
                      "es",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )
                  }`}
                </span>
              </div>
            </Link>
          ) : (
            <a
              href={`${window.location.origin}/${props.path}`}
              target="_blank"
              rel="noopener noreferrer"
              className="music-card-link"
            >
              <div className="music-card-cover">
                {props.covers ? (
                  <img
                    width={290}
                    height={290}
                    loading="eager"
                    src={props.covers[0]}
                    className="music-cover"
                    alt={`Portada de "${props.title}" de Daniel Ospid`}
                    title={`Portada de "${props.title}" de Daniel Ospid`}
                  />
                ) : (
                  <div
                    className="music-cover"
                    style={{ backgroundColor: `#${props.color}80` }}
                  >
                    • • •
                  </div>
                )}
                {props.songs && (
                  <div
                    className="music-card-songs"
                    style={{ backgroundColor: `#${props.color}` }}
                  >
                    <span className="music-card-songs-icon">
                      <img
                        src={note}
                        width={16}
                        height={16}
                        loading="eager"
                        alt="Nota musical"
                        title="Nota musical"
                      />
                    </span>
                    <span className="music-card-songs-text">
                      {props.songs.length > 1 ? (
                        <>{props.songs.length + " Canciones"}</>
                      ) : (
                        <>{props.songs.length + " Canción"}</>
                      )}
                    </span>
                  </div>
                )}
                <div className="music-card-screen"></div>
              </div>
              <div className="music-card-caption">
                <span className="music-card-title">{props.title}</span>
                <span className="music-card-type">
                  {`${props.type} • ${
                    props.date &&
                    new Date(props.date.seconds * 1000).toLocaleDateString(
                      "es",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )
                  }`}
                </span>
              </div>
            </a>
          )}
        </div>
      )}
    </>
  );
}

export default MusicCard;
