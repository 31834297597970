import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { useMusic } from "../../firebase/fbQueries";
import MusicPage from "./MusicPage";
import MusicPageLoad from "./MusicPageLoad";

function Music() {
  const { path } = useParams();

  const music = useMusic(db);
  const musicSelected = music.find((music) => music.path === path);

  return (
    <main className="main">
      <section className="section">
        <div className="container">
          {musicSelected ? <MusicPage {...musicSelected} /> : <MusicPageLoad />}
        </div>
      </section>
    </main>
  );
}
export default Music;
