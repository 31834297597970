import { useEffect } from "react";

const HeadTags = ({ title, path, covers = [] }) => {
  useEffect(() => {
    if (title) document.title = `${title} ~ Daniel Ospid`;

    const setMetaTag = (name, content, property = false) => {
      let selector = property
        ? `meta[property="${name}"]`
        : `meta[name="${name}"]`;
      let element = document.querySelector(selector);

      if (!element) {
        element = document.createElement("meta");
        if (property) element.setAttribute("property", name);
        else element.setAttribute("name", name);
        document.head.appendChild(element);
      }

      element.setAttribute("content", content);
    };

    const canonicalUrl = `${window.location.origin}/${path}`;
    let linkCanonical = document.querySelector("link[rel='canonical']");
    if (!linkCanonical) {
      linkCanonical = document.createElement("link");
      linkCanonical.rel = "canonical";
      document.head.appendChild(linkCanonical);
    }
    linkCanonical.href = canonicalUrl;

    // Meta Tags
    setMetaTag("title", `${title} ~ Daniel Ospid`);
    setMetaTag(
      "description",
      `Escucha "${title}" en tu plataforma digital favorita. Descubre más sobre este lanzamiento musical y disfruta de la mejor música de Daniel Ospid.`
    );

    // Open Graph
    setMetaTag("og:title", `${title} ~ Daniel Ospid`, true);
    setMetaTag(
      "og:description",
      `Escucha "${title}" en tu plataforma digital favorita. Descubre más sobre este lanzamiento musical y disfruta de la mejor música de Daniel Ospid.`,
      true
    );
    setMetaTag("og:url", canonicalUrl, true);
    if (covers.length > 0) {
      setMetaTag("og:image", covers, true);
      setMetaTag("og:image:width", "924", true);
      setMetaTag("og:image:height", "924", true);
      setMetaTag("og:image:type", "image/png", true);
    }

    // Twitter (X)
    setMetaTag("twitter:card", "summary", true);
    setMetaTag("twitter:url", canonicalUrl, true);
    setMetaTag("twitter:title", `${title} ~ Daniel Ospid`, true);
    setMetaTag("twitter:site", `@danielospid`, true);
    setMetaTag("twitter:creator", `@danielospid`, true);
    setMetaTag(
      "twitter:description",
      `Escucha "${title}" en tu plataforma digital favorita. Descubre más sobre este lanzamiento musical y disfruta de la mejor música de Daniel Ospid.`,
      true
    );
    if (covers.length > 0) {
      setMetaTag("twitter:image", covers, true);
    }
  }, [title, path, covers]);

  return null;
};

export default HeadTags;
