import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";


// ---- ---- admin ---- ---- //
const useIsAdmin = (db) => {
  const authUser = useAuth(db);
  const users = useUsers(db);

  // Encuentra al usuario admin
  const admin = users.find((user) => user.email === "hello@danielospid.com");

  // Verifica si el usuario autenticado es el admin
  const isAdmin = authUser && admin && authUser?.email === admin?.email;

  return isAdmin;
};

export default useIsAdmin;

// ---- ---- music ---- ---- //
export function useMusic(db) {
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const musicRef = collection(db, "music");

    const unsubscribe = onSnapshot(musicRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMusic(data);
    });

    return () => unsubscribe();
  }, [db]);

  return music;
}

// ---- ---- playlists ---- ---- //
export function usePlaylists(db) {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const playlistsRef = collection(db, "playlists");

    const unsubscribe = onSnapshot(playlistsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPlaylists(data);
    });

    return () => unsubscribe();
  }, [db]);

  return playlists;
}

// ---- ---- authentication ---- ---- //
export function useAuth(db) {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return authUser;
}

// ---- ---- users ---- ---- //
export function useUsers(db) {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const usersRef = collection(db, "users");

    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setUsers(data);
    });

    return () => unsubscribe();
  }, [db]);

  return users;
}