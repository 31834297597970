import Main from "./Main";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signOut } from "firebase/auth";
// import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import useIsAdmin from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "lucide-react";

function Layout() {
  const auth = getAuth();
  const isAdmin = useIsAdmin(db);
  const navegate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F2") {
        navegate("/login");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  return (
    <div className="app home">
      <Main />
      {isAdmin && (
        <>
          <button
            onClick={() => handleSignOut()}
            title="Cerrar Sesión"
            className="sign-out"
          >
            <LogOut size={20} />
          </button>
        </>
      )}
    </div>
  );
}
export default Layout;
