import "./styles/app.css";
import { Navigate, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Music from "./components/pages/Music";
import Layout from "./components/layout/Layout";
import Login from "./components/pages/Login";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/:path" element={<Music />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
