import { Search, X } from "lucide-react";
import { db } from "../../firebase/config";
import { useMusic } from "../../firebase/fbQueries";
import MusicCard from "../common/MusicCard";
import MusicCardLoad from "../common/MusicCardLoad";
import { useState } from "react";
import {
  // useLocation,
  useNavigate,
} from "react-router-dom";
// import queryString from "query-string";

function Home() {
  const music = useMusic(db);
  // const { home } = useLocation();
  // const { search = "" } = queryString.parse(home);
  const [searchTerm, setSearchTerm] = useState("");
  const sameTitleMusic = music.filter((music) => {
    const matchesSearch = music.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const encodedSearchTerm = searchTerm.replace(/ /g, "+");
    navigate(`/?title=${encodedSearchTerm}`);
  };

  const resetInput = () => {
    setSearchTerm("");
    navigate("/");
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="banner-search">
            <div className="blur"></div>
            <h1>Busca Tus Lanzamiento Favorito de Daniel Ospid</h1>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Buscar por título..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="reset" title="Limpiar" onClick={() => resetInput()}>
                <X size={18} />
              </button>
              <button type="submit" title="Buscar">
                <Search size={20} />
                {/* <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" /> */}
              </button>
            </form>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="grid">
            {sameTitleMusic.length === 0 ? (
              <>
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
              </>
            ) : (
              sameTitleMusic
                .sort((a, b) => b.date - a.date)
                .map((music, index) => <MusicCard key={index} {...music} />)
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
